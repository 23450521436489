import Vue from 'vue'
import { FilterTypes } from 'constants/tripla'
import _keyBy from 'lodash/keyBy'

export const mutations = {
  setSearchForm: (state, payload) => {
    state.searchForm = {
      ...state.searchForm,
      ...payload
    }
  },
  setChatID: (state, chatID) => {
    state.chatID = chatID
  },
  setRooms: (state, rooms) => {
    state.rooms = rooms
    state.preloadRooms = null
    state.preloadPlans = null
  },
  setPlans: (state, plans) => {
    state.plans = plans
    state.preloadRooms = null
    state.preloadPlans = null
  },
  setExtraInventories: (state, extraInventories = []) => {
    state.extraInventories = _keyBy(extraInventories, 'extra_id')
  },
  setPreloadRooms: (state, rooms) => {
    if (!state.rooms) state.preloadRooms = rooms
  },
  setPreloadPlans: (state, plans) => {
    if (!state.plans) state.preloadPlans = plans
  },
  clearPreload: (state) => {
    state.preloadRooms = null
    state.preloadPlans = null
  },
  setAttrInSearchForm: (state, { item, attr, value }) => {
    Vue.set(item, attr, value)
  },
  addRoomInSearchForm: (state, newRoom) => {
    state.searchForm.rooms.push(newRoom)
  },
  removeRoomInSearchForm: (state, roomIndex) => {
    state.searchForm.rooms.splice(roomIndex, 1)
  },
  setRoomSelectedCount: (
    state,
    {
      type = 'rooms',
      roomTypeCode: selectedRoomTypeCode,
      roomPlanCode: selectedRoomPlanCode,
      numberOfAdults,
      numberOfChildren,
      value,
      salePrice,
      tax,
      scrapeIds,
      promotionID,
      couponCode
    }
  ) => {
    // If this combination of room type + room plan already exists, update the quantity
    // Else just create a new record
    const selectedRoomIndex = state.selectedRooms.findIndex(
      (room) =>
        room.roomTypeCode === selectedRoomTypeCode &&
        room.roomPlanCode === selectedRoomPlanCode &&
        room.type === type &&
        room.numberOfAdults === numberOfAdults &&
        room.numberOfChildren === numberOfChildren
    )

    if (selectedRoomIndex !== -1) {
      Vue.set(state.selectedRooms, selectedRoomIndex, {
        roomTypeCode: selectedRoomTypeCode,
        roomPlanCode: selectedRoomPlanCode,
        numberOfAdults,
        numberOfChildren,
        quantity: value,
        type,
        salePrice,
        tax,
        scrapeIds,
        promotionID,
        couponCode
      })
    } else {
      state.selectedRooms.push({
        roomTypeCode: selectedRoomTypeCode,
        roomPlanCode: selectedRoomPlanCode,
        numberOfAdults,
        numberOfChildren,
        quantity: value,
        type,
        salePrice,
        tax,
        scrapeIds,
        promotionID,
        couponCode
      })
    }
  },
  decreaseRoomSelectedCount: (state, { roomCode, planCode, adults, children, type }) => {
    const selectedRoomIndex = state.selectedRooms.findIndex(
      (selectedRoom) =>
        selectedRoom.roomTypeCode === roomCode &&
        selectedRoom.roomPlanCode === planCode &&
        selectedRoom.numberOfAdults === adults &&
        selectedRoom.numberOfChildren === children &&
        selectedRoom.type === type
    )
    const selectedRoom = state.selectedRooms[selectedRoomIndex]

    if (!selectedRoom || selectedRoom.quantity === 0) return

    Vue.set(state.selectedRooms, selectedRoomIndex, {
      ...selectedRoom,
      quantity: selectedRoom.quantity - 1
    })
  },
  clearSelectedRooms: (state) => {
    state.selectedRooms = []
  },
  setGalleryPhotos: (state, payload) => {
    state.galleryPhotos = payload
  },
  setGalleryIndex: (state, index) => {
    state.galleryIndex = index
  },
  setCancellationPolicyContent: (state, content) => {
    state.cancellationPolicy.content = content
  },
  clearPlans: (state) => {
    state.plans = []
  },
  clearRooms: (state) => {
    state.rooms = []
  },
  setInitAPIArrived: (state, payload) => {
    state.initAPIArrived = payload
  },
  setPlanDetails: (state, plan) => {
    state.planDetails = plan
  },
  setRoomDetails: (state, roomDetails) => {
    state.roomDetails = roomDetails
  },
  setTotalPriceFromSearch: (state, price) => {
    state.totalPriceFromSearch = price
  },
  removeSearchFormFilter: (state, filter) => {
    let searchFormKey = ''
    switch (filter.type) {
      case FilterTypes.Filter:
        searchFormKey = 'filter'
        break
      case FilterTypes.HotelPlanCode:
        searchFormKey = 'hotelPlanCodes'
        break
      case FilterTypes.RoomTypeCode:
        searchFormKey = 'roomTypeCodes'
        break
      default:
        break
    }

    const filterIndex = state.searchForm?.[searchFormKey]?.findIndex((t) => t === filter.name)
    if (filterIndex > -1) {
      state.searchForm[searchFormKey].splice(filterIndex, 1)
    }
  },
  setCurrentSearchParams: (state, data) => {
    state.currentSearchParamsForAPI = data

    try {
      sessionStorage.setItem('currentSearchParamsForAPI', JSON.stringify(data))
    } catch (_e) {
      // pass
    }
  },
  setCurrentSearchParamsHotelPlans: (state, data) => {
    state.currentSearchParamsHotelPlans = data
  },
  setCurrentSearchParamsRoomTypes: (state, data) => {
    state.currentSearchParamsRoomTypes = data
  },
  setNotificationMap: (state, notificationMap) => {
    state.notificationMap = notificationMap
  },
  setRoomRateMap: (state, roomRateMap) => {
    state.roomRateMap = roomRateMap
  },
  setPromotionMap: (state, promotionMap) => {
    state.promotionMap = promotionMap
  },
  setWbfPriceMap: (state, { action, payload, reset }) => {
    if (reset) {
      state.wbfPriceMap = {}
    } else {
      Vue.set(state.wbfPriceMap, action, payload)
    }
  },
  setRoomSearchSuccess: (state, roomSearchSuccess) => {
    state.roomSearchSuccess = roomSearchSuccess
  },
  setFacilityAdditionalQuestionsSuccess: (state, facilityAdditionalQuestionsSuccess) => {
    state.facilityAdditionalQuestionsSuccess = facilityAdditionalQuestionsSuccess
  }
}
