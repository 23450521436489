import jaLocales from 'src/locales/ja_locales'

// from backend
export default {
  INVALID: 1000,

  // extras
  EXTRA_ID_INVALID: 1100,
  EXTRA_BOOKING_DEADLINE_EXCEEDED: 1101,
  EXTRA_QUANTITY_INVALID: 1102,
  EXTRA_TOTAL_PRICE_INVALID: 1103,
  EXTRA_PURCHASE_LIMIT_EXCEEDED: 1104,
  EXTRA_PURCHASE_LIMIT_INSUFFICIENT: 1105,
  EXTRA_NO_LONGER_SOLD: 1106,

  JOB_HAS_NOT_FINISHED: 1200,
  EXPIRED: 1250,
  UNPROCESSABLE_UPLOAD_FILE: 1251,
  DOWNLOAD_FILE_NOT_FOUND: 1252,

  PASSWORD_STRENGTH: 1300,
  ACCOUNT_LOCKED: 1301,
  PASSWORD_USED: 1302,

  SESSION_EXPIRED: 1400,
  SIGN_UP_CONFIRMATION_PERIOD_EXPIRED: 1401,
  USER_ALREADY_CONFIRMED: 1402,

  COUPON_INVALID: 1500,

  OVER_RESERVATION_CSV_DOWNLOAD_LIMIT: 1600,

  // promotion
  PROMOTION_CANT_APPLY_TO_PLAN: 2001,

  // hotel plan
  HOTEL_PLAN_CODE_DUPLICATE: 2101,

  // room_type
  ROOM_TYPE_WITH_UPCOMING_RESERVATION: 2201,

  // tripla-pay
  TRIPLA_PAY_NOT_UPDATABLE: 3200,
  TRIPLA_PAY_NOT_CAPTURABLE: 3201,

  // Magic Numbers from Backend
  IDP_EXPIRED: 4000,

  // tripla session cookie related
  TRIPLA_SESSION_TOKEN_PROVIDED_BUT_NOT_FOUND: 3401,
  TRIPLA_SESSION_FOUND_BUT_HOTEL_BRAND_MISMATCHED: 3402,

  // GHA
  GHA_JAPANESE_ONLY_PLAN_INVALID_LANGUAGE: 3601,
  GHA_NON_JAPANESE_ONLY_PLAN_INVALID_LANGUAGE: 3602,

  // creansmaerd
  CREANSMAERD_GENERAL_ERROR: 3501,
  CREANSMAERD_POINT_BALANCE_ERROR: 3502,
  CREANSMAERD_MOVE_POINTS_ERROR: 3503,
  CREANSMAERD_CANCEL_POINT_ERROR: 3504,
  NOT_ENOUGH_POINTS: 2401
}

export const TRANSLATED_ERROR_CODES = Object.keys(jaLocales.base.errorCode).map(Number)
