<template>
  <div v-if="showMenuButton" v-loading="isLoading" class="sign-in-button-container">
    <button
      id="loginPopover"
      v-theme-font="isMobile && showLoginItems"
      class="navbar-button"
      @click="toggleMobileLogin"
    >
      <i class="header-icon" :style="isMobile && showLoginItems ? `fill: ${iconColor}` : ''">
        <business-icon v-if="isCorporateSignedIn" />
        <person-icon v-else />
      </i>
      <span class="login-display">{{ loginDisplayText }}</span>
    </button>
    <b-popover
      v-if="!isMobile"
      ref="loginPopover"
      target="loginPopover"
      triggers="click blur"
      placement="bottom"
      custom-class="popover-no-padding"
      :show="showLoginItems"
      :delay="200"
      @show="$emit('toggleLoginItems', true)"
      @hide="$emit('toggleLoginItems', false)"
    >
      <portal-target name="membership-setting" />
    </b-popover>
    <portal to="membership-setting">
      <ul class="navbar-list-items">
        <li v-if="isCorporateSignedIn" class="disabled">{{ loginDisplayText }}</li>
        <li v-if="canGoAdmin" @click="goToAdminPage">
          {{ $t('corporateAdmin.admin') }}
        </li>
        <li v-if="canGoCustomMyPage" @click="handleMyPageClick">
          {{ $t('myProfile.myPage') }}
        </li>
        <template v-else-if="canGoAccount">
          <li v-if="!creansmaerd?.active" @click="handleAccountClick">
            {{ $t('base.account') }}<span v-if="isMobile"> / {{ userName }}</span>
          </li>
          <li @click="handleReservationsClick">
            {{ $t('myProfile.myReservations') }}
          </li>
          <li
            v-if="canUsePointProgram && !creansmaerd?.active"
            id="go-to-my-points-page"
            @click="handlePointsClick"
          >
            {{ pointName }}
          </li>
          <li @click="handlePaymentClick">
            {{ $t('myAccount.paymentOptions') }}
          </li>
        </template>
        <li v-if="canMembershipLogin" @click="handleSignInClick">
          <span class="text">{{ $t('base.memberSignIn') }}</span>
        </li>
        <li v-if="canCorporateLogin" @click="handleCorpSignInClick">
          {{ $t('organization.signIn') }}
        </li>
        <hr v-if="showHr" class="my-0" />
        <li v-if="canSignUp" @click="handleSignUpClick">
          {{ signUpText }}
        </li>
        <li v-if="canLogout" @click="handleLogout">
          {{ $t('base.logout') }}
        </li>
      </ul>
    </portal>
  </div>
</template>

<script>
import paths from 'routes/paths'
import { mapState, mapActions, mapGetters } from 'vuex'
import _get from 'lodash/get'
import { isAppliedMemberSignUpText } from 'utilities/membershipSignUpText'
import BrowsingState from 'utilities/BrowsingState'
import PersonIcon from 'assets/icons/base-person.svg?inline'
import BusinessIcon from 'assets/business.svg?inline'
import getServerUrl from 'api/domain'
import { setSearchCriteria } from 'utilities/searchCriteriaCookie'
import { appendParams } from 'utilities/paramsFormat'
import EventBus from 'utilities/EventBus'

export default {
  name: 'LoginWidget',
  components: {
    PersonIcon,
    BusinessIcon
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    showLoginItems: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      paths
    }
  },
  computed: {
    ...mapState({
      translationsAttributes: (state) =>
        _get(state, 'setting.loginWidget.translations_attributes', []),
      isMembershipLoginEnable: (state) => _get(state, 'setting.loginWidget.is_active', false)
    }),
    ...mapGetters({
      hotelId: 'setting/getHotelId',
      hotelCode: 'setting/getHotelCode',
      isSignUpActive: 'setting/isSignUpActive',
      isVoucherMode: 'search/isVoucherMode',
      isCorporateMembershipLoginEnable: 'setting/isCorporateMembershipLoginEnable',
      setting: 'setting/getSetting',
      init: 'setting/getInit',
      creansmaerd: 'setting/getCreansmaerd',
      brandIntegrations: 'setting/getBrandIntegrations',
      searchForm: 'search/getSearchForm',
      brandId: 'setting/getBrandID',
      canUsePointProgram: 'membership/canUsePointProgram',
      isLotteryEntry: 'lottery/getIsLotteryEntry',
      isLotteryBooking: 'booking/isLotteryBooking',
      iconColor: 'setting/getColor',
      pointName: 'setting/getPointName'
    }),
    ...mapGetters('membership', {
      user: 'getCurrentUser',
      userName: 'getUserName',
      isCorporateSignedIn: 'isCorporateSignedIn',
      isOrganizationAdmin: 'isOrganizationAdmin',
      isUserSignedIn: 'isUserSignedIn',
      organizationName: 'getOrganizationName'
    }),
    usingCustomAuth() {
      return !!this.init.brand_custom_auth_token_name
    },
    canMembershipLogin() {
      return !this.isUserSignedIn && !this.usingCustomAuth && this.isMembershipLoginEnable
    },
    canCorporateLogin() {
      return (
        !this.isCorporateSignedIn &&
        this.isCorporateMembershipLoginEnable &&
        !this.isLotteryEntry &&
        this.$route.name !== paths.roomLottery.confirmation
      )
    },
    canGoAdmin() {
      return this.isOrganizationAdmin
    },
    canGoAccount() {
      return this.isUserSignedIn && (this.isMembershipLoginEnable || this.usingCustomAuth)
    },
    canGoCustomMyPage() {
      return this.canGoAccount && this.brandIntegrations?.membership_menu_redirect?.active
    },
    canLogin() {
      return this.isMembershipLoginEnable || this.isCorporateMembershipLoginEnable
    },
    canSignUp() {
      return (
        this.isSignUpActive &&
        this.isMembershipLoginEnable &&
        !this.isVoucherMode &&
        !this.isUserSignedIn &&
        !this.isCorporateSignedIn &&
        !this.usingCustomAuth
      )
    },
    canLogout() {
      const hideLogout = (process.env.HIDE_LOGOUT_BRANDS || '')
        .split(',')
        .includes(String(this.brandId))
      return !hideLogout && (this.isCorporateSignedIn || this.isUserSignedIn)
    },
    showMenuButton() {
      return (
        this.canMembershipLogin ||
        this.canCorporateLogin ||
        this.canGoAdmin ||
        this.canGoAccount ||
        this.canSignUp ||
        this.canLogout
      )
    },
    showHr() {
      const hasMenu1Item =
        this.canMembershipLogin || this.canCorporateLogin || this.canGoAccount || this.canGoAdmin
      const hasMenu2Item = this.canSignUp || this.canLogout
      return hasMenu1Item && hasMenu2Item
    },
    signUpText() {
      return isAppliedMemberSignUpText()
        ? this.$t('base.memberSignUp', this.$i18n.locale)
        : this.$t('membershipForm.signUp', this.$i18n.locale)
    },
    loginDisplayText() {
      if (this.isCorporateSignedIn && this.isUserSignedIn) {
        return `${this.organizationName}/${this.userName}`
      } else if (this.isCorporateSignedIn) {
        return this.organizationName
      } else if (this.isUserSignedIn) {
        return this.userName
      }
      return this.$t('base.login', this.$i18n.locale)
    },
    loginBtnCustomRedirectURL() {
      const customRedirect = this.brandIntegrations.membership_customer_login_redirect
      if (customRedirect?.active && customRedirect.settings?.url) {
        const targetURL = this.isCorporateSignedIn
          ? customRedirect.settings?.with_organization_url
          : customRedirect.settings?.url

        const queries = {
          hotel_code: this.hotelCode,
          redirect_url: encodeURIComponent(
            this.$route.query?.urlBack || this.$route?.fullPath || ''
          )
        }

        return appendParams(targetURL, {
          ...(this.isCorporateSignedIn
            ? { org_session: this.user.session || this.user.organization?.session, ...queries }
            : { ...queries })
        })
      }
      return ''
    }
  },
  methods: {
    ...mapActions('membership', ['logout']),
    saveBrowsingState() {
      BrowsingState.save(this.brandId, {
        searchForm: this.searchForm,
        code: this.hotelCode,
        query: this.$route.query
      })
    },
    handleSignUpClick() {
      this.closeDropdown()
      if (this.creansmaerd?.active) {
        setSearchCriteria()
        const serverUrl = `${getServerUrl()}/hotels/${
          this.hotelCode
        }/creansmaerd/callback?otid=%OTID%`
        window.location = `${
          this.creansmaerd.settings.login_host
        }/mypage/pc/register_apply_input.php?rqu=${encodeURIComponent(serverUrl)}`
      } else if (this.$route.name !== paths.membership.signUp) {
        this.$router.push({
          name: paths.membership.signUp,
          query: { search: this.$route.fullPath, code: this.setting.code }
        })
      }
    },
    handleSignInClick() {
      this.closeDropdown()

      if (this.loginBtnCustomRedirectURL) {
        this.saveBrowsingState()
        location = this.loginBtnCustomRedirectURL
        return
      }

      if (this.creansmaerd?.active) {
        setSearchCriteria()
        const serverUrl = `${getServerUrl()}/hotels/${
          this.hotelCode
        }/creansmaerd/callback?otid=%OTID%`
        window.location = `${
          this.creansmaerd.settings.login_host
        }/mypage/pc/login.php?rqu=${encodeURIComponent(serverUrl)}`
      } else {
        this.redirectToPage(paths.membership.signIn)
      }
    },
    redirectToPage(routerName) {
      if (this.$route.name !== routerName) {
        this.$router.push({
          name: routerName,
          query: {
            urlBack:
              this.$route.name.includes('SignIn') || this.$route.name.includes('SignUp')
                ? null
                : this.$route.query?.urlBack || this.$route.fullPath,
            code: this.setting.code
          }
        })
      }
    },
    handleCorpSignInClick() {
      this.closeDropdown()
      this.redirectToPage(paths.organization.signIn)
    },
    handleLogout() {
      this.closeDropdown()
      this.isLoading = true
      const targetRoute =
        this.$route.name === paths.roomLottery.confirmation
          ? {
              name: paths.booking.result,
              query: { code: this.hotelCode }
            }
          : undefined

      this.logout({ targetRoute })
        .then(() => {
          this.isLoading = false
          EventBus.$emit('reloadView')
        })
        .catch((error) => {
          this.isLoading = false
          console.warn(error) // TODO: display alert?
        })
    },
    handleAccountClick() {
      this.closeDropdown()
      this.$router.push({
        name: paths.myAccount.profile,
        query: {
          code: this.setting.code
        }
      })
    },
    handleReservationsClick() {
      this.closeDropdown()
      this.$router.push({
        name: paths.myAccount.reservations,
        query: {
          code: this.setting.code
        }
      })
    },
    handlePointsClick() {
      this.closeDropdown()
      this.$router.push({
        name: paths.myAccount.points,
        query: {
          code: this.setting.code
        }
      })
    },
    handlePaymentClick() {
      this.closeDropdown()
      this.$router.push({
        name: paths.myAccount.paymentOptions,
        query: {
          code: this.setting.code
        }
      })
    },
    handleMyPageClick() {
      const customMyPageUrl = appendParams(
        this.brandIntegrations.membership_menu_redirect.settings.url,
        {
          tripla_session: this.user.session
        }
      )

      this.closeDropdown()
      window.open(customMyPageUrl)
    },
    goToAdminPage() {
      this.closeDropdown()
      this.$router.push({ name: paths.organization.admin, query: { code: this.setting.code } })
    },
    closeDropdown() {
      if (!this.isMobile) {
        this.$refs.loginPopover.$emit('close')
      } else {
        this.$emit('toggleLoginItems', false)
      }
    },
    toggleMobileLogin() {
      if (this.isMobile) {
        this.$emit('toggleLoginItems', !this.showLoginItems)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/mixins.scss';
@import '~stylesheets/vars.scss';

.sign-in-button-container {
  display: flex;
  align-items: center;
}

.search-btn {
  min-width: 110px;
}

.user-name-label {
  color: $brown;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.icon {
  height: 20px;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 4px;
}

.navbar-button.no-option {
  &:after {
    display: none;
  }
}

.navbar-list-items {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 7px 10px;
    cursor: pointer;

    &:hover,
    &.disabled {
      background: $sub-background;
    }
  }
}

.login-display {
  max-width: 194px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

@include media-breakpoint-down(md) {
  .navbar-list-items li {
    padding: 13px 10px;
  }

  .login-display {
    max-width: 180px;
  }
}
</style>
