import getServerUrl, { getIdentityServerUrl } from './domain'
import store from 'store'
import _get from 'lodash/get'
import { appendParams } from 'utilities/paramsFormat'
import i18n from '../i18n'

export const initURL = (code = store.getters['setting/getSetting'].code) => {
  return `${getServerUrl()}/hotels/${code}/settings/booking_widget`
}

export const loginWidgetSettingURL = () => {
  const { code } = store.getters['setting/getSetting']
  return `${getServerUrl()}/hotels/${code}/settings/sign_in_button`
}

export const userInfo = () => {
  return `${getServerUrl()}/user/me`
}

export const userIdentity = () => {
  const { code } = store.getters['setting/getSetting']
  return `${getServerUrl()}/hotels/${code}/tripla_session/whoami`
}

export const searchRoomURL = () => {
  return `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    store.getters['setting/getHotelCode']
  )}/rooms`
}

export const recommendationRoomURL = () => {
  return `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    store.getters['setting/getHotelCode']
  )}/rooms/recommendation`
}

export const reservationURL = (hotelId) => {
  if (!hotelId) {
    hotelId = store.getters['setting/getInit']?.hotel_id
  }
  return `${getServerUrl()}/hotels/${hotelId}/reservations`
}

export const storageURL = () => {
  return `${getServerUrl()}/api/ota/v1/temporary_storages/`
}

export const restoreStorageUrl = (storageKey) => {
  return `${getServerUrl()}/api/ota/v1/temporary_storages/${storageKey}`
}

export const reservationConfirmURL = ({ reservationID, hotelId }) => {
  return `${reservationURL(hotelId)}/${reservationID}`
}

export const updateReservationURL = ({ reservationID, hotelId }) => {
  return `${reservationURL(hotelId)}/${reservationID}/update_settings`
}

export const patchPaymentHubTransactionURL = ({ reservationID, hotelId, transactionId }) => {
  return `${reservationURL(hotelId)}/${reservationID}/payment_hub/transactions/${transactionId}`
}

export const additionalQuestionURL = ({ reservationID, hotelId }) => {
  return `${reservationURL(hotelId)}/${reservationID}/additional_questions`
}

export const editAdditionalQuestionURL = ({ reservationID, hotelId }) => {
  return `${additionalQuestionURL({ reservationID, hotelId })}/batch_update`
}

export const additionalQuestionListURL = () => {
  return `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    store.getters['setting/getHotelCode']
  )}/additional_questions`
}

export const brandAdditionalQuestionURL = () => {
  return `${getServerUrl()}/hotel_brands/${
    store.getters['setting/getInit']?.hotel_brand_id
  }/additional_questions`
}

export const reservationReceiptURL = ({ reservationID, name, email, token, hotelId }) => {
  const url = `${reservationURL(hotelId)}/${reservationID}/receipt`
  return appendParams(url, {
    name,
    email,
    bypass_token: token,
    locale: i18n.locale
  })
}

export const reservationGuestURL = ({ reservationID, hotelId }) => {
  return `${reservationURL(hotelId)}/${reservationID}/guests`
}

export const searchReservationURL = (reservationID, email) =>
  `${getServerUrl()}/hotel_brands/${
    store.getters['setting/getBrandID']
  }/reservations/${reservationID}?email=${encodeURIComponent(email)}`

export const otaSearchConfirmReservationURL = (reservationID, payload) =>
  appendParams(`${getServerUrl()}/api/ota/v1/reservations/${reservationID}`, payload)

export const reservationCancelURL = ({ reservationID, email, token, hotelId }) => {
  const url = `${reservationURL(hotelId)}/${reservationID}/cancel`
  return appendParams(url, { email, bypass_token: token })
}

export const reservationPaypalClientTokenURL = () => {
  return `${reservationURL(
    _get(store.getters['setting/getInit'], 'hotel_id', null)
  )}/paypal_payments/client_credential`
}

export const reservationPaypalOrderURL = () => {
  return `${reservationURL(
    _get(store.getters['setting/getInit'], 'hotel_id', null)
  )}/paypal_payments/order`
}

export const reservationValidateCreditCardURL = () => {
  return `${reservationURL(
    _get(store.getters['setting/getInit'], 'hotel_id', null)
  )}/paypal_payments/validate_credit_card`
}

export const getRoomsInfoURL = () => {
  return `${searchRoomURL()}/price`
}

export const roomPriceUrl = (id) => {
  return `${getServerUrl()}/hotels/${id}/rooms/price`
}

export const cancellationPolicyURL = (
  hotelId = _get(store.getters['setting/getInit'], 'hotel_id', null)
) => {
  return `${getServerUrl()}/hotels/${hotelId}/cancellation_policies`
}

export const getCurrentPointURL = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/point`

export const loginURL = (hotelId) => {
  return `${getServerUrl()}/hotels/${hotelId}/memberships/sign_in`
}

export const signUpURL = () => {
  const hotelId = _get(store.getters['setting/getInit'], 'hotel_id', null)
  return `${getServerUrl()}/hotels/${hotelId}/memberships/sign_up`
}

export const getChatbotSessionURL = (hotelCode) => {
  return `${getServerUrl()}/hotels/${hotelCode}/memberships/chatbot_session`
}

export const switchMembershipURL = () => {
  return `${getServerUrl()}/hotels/${store.getters['setting/getSetting'].code}/memberships/switch`
}

export const updatePasswordURL = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/update_password`

export const resetPasswordURL = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/start_reset_password_process`

export const signOutURL = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/sign_out`

export const searchReservationByUserURL = (params) =>
  appendParams(`${getServerUrl()}/user/reservations`, params)

export const confirmURL = () => {
  return `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/confirm`
}

export const getPointHistory = (hotelId, params) =>
  appendParams(`${getServerUrl()}/hotels/${hotelId}/memberships/points_timeline`, params)
export const getExchangeHistory = (hotelId, page, perPage) =>
  `${getServerUrl()}/hotels/${hotelId}/memberships/point_exchanges?page=${page}&per_page=${perPage}`
export const postExchange = (hotelId) =>
  `${getServerUrl()}/hotels/${hotelId}/memberships/point_exchanges`

export const getSessionsTokenURL = () => `${getIdentityServerUrl()}/api/client_sessions`

export const calendarInventoryURL = (hotelId) =>
  `${getServerUrl()}/hotels/${hotelId}/rooms/inventory`

export const calendarRateURL = (hotelId) => `${getServerUrl()}/hotels/${hotelId}/rooms/availability`

export const unsubscribeEmailURL = () => `${getServerUrl()}/api/v1/marketing_emails/unsubscribe`

export const unsubscribeEmailSubscriptionStatusURL = () =>
  `${getServerUrl()}/api/v1/marketing_emails/subscription_status`

export const showURL = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/show`

export const updateEmailUrl = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/update_email`

export const resendConfirmEmailUrl = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/resend_confirm_email`

export const updateUserUrl = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/update`

export const deleteAccountURL = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/cancel`

export const getProgramURL = (id) => {
  const url = `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/program`
  return id ? `${url}/${id}` : url
}

export const getCreditCardsURL = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/credit_cards`
export const deleteCreditCardURL = (id, data) =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    data
  )}/memberships/credit_cards/${id}`

export const getBrandProgramURL = () =>
  `${getServerUrl()}/hotel_brands/${store.getters['setting/getBrandID']}/membership_programs`

export const resendConfirmationURL = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/resend_confirmation`

export const facebookLoginURL = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/facebook/sign_in`

export const lineLoginURL = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/line/sign_in`

export const creansmaerdLoginURL = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/creansmaerd/sign_in`

export const openIdLoginURL = () =>
  `${getServerUrl()}/hotels/${_get(
    store.getters['setting/getInit'],
    'hotel_id',
    null
  )}/memberships/open_id/sign_in`

export const orgSignInURL = () =>
  `${getServerUrl()}/hotels/${store.getters['setting/getSetting'].code}/organization/sign_in`
export const orgReservationHistoryURL = () =>
  `${getServerUrl()}/hotel_brands/${
    store.getters['setting/getInit']?.hotel_brand_id
  }/organizations/${store.getters['membership/getOrganization']?.id}/reservations`
export const orgDetails = () =>
  `${getServerUrl()}/hotel_brands/${
    store.getters['setting/getInit']?.hotel_brand_id
  }/organizations/${store.getters['membership/getOrganization']?.id}`

export const verifySignUpDetailsURL = () => {
  const hotelId = _get(store.getters['setting/getInit'], 'hotel_id', null)
  return `${getServerUrl()}/hotels/${hotelId}/memberships/sign_up/verify`
}

export const setupURL = () => {
  const hotelId = _get(store.getters['setting/getInit'], 'hotel_id', null)
  return `${getServerUrl()}/hotels/${hotelId}/memberships/setup`
}

export const extrasURL = () => {
  const hotelId = _get(store.getters['setting/getInit'], 'hotel_id', null)
  return `${getServerUrl()}/hotels/${hotelId}/extras`
}

export const hotelListUrl = () => {
  return `${getServerUrl()}/hotel_brands/${store.getters['setting/getInit']?.hotel_brand_id}/hotels`
}

export const searchHotelsUrl = () => {
  return `${getServerUrl()}/api/ota/v1/hotels/search`
}

export const searchRatingsHotelsUrl = () => {
  return `${getServerUrl()}/api/ota/v1/hotels/ratings`
}

export const newTokenURL = (hotel_code) => {
  return `${getServerUrl()}/hotels/${hotel_code}/memberships/tokens`
}

export const postAttachmentURL = () => {
  const hotelId = _get(store.getters['setting/getInit'], 'hotel_id', null)
  return `${getServerUrl()}/hotels/${hotelId}/file_attachments`
}

export const triplaPayURL = (paymentNo) => {
  const hotelId = _get(store.getters['setting/getInit'], 'hotel_id', null)
  return `${getServerUrl()}/hotels/${hotelId}/tripla_pay/transactions/${paymentNo}`
}

export const qrCode = (content) => `${getServerUrl()}/qr_codes?qr_code=${escape(content)}`

export const hotelInfoUrl = (id) => {
  return `${getServerUrl()}/hotels/${id}`
}

export const hotelInventoryUrl = (brandId) => {
  return `${getServerUrl()}/hotel_brands/${brandId}/inventory`
}

export const lotteryEventSettingURL = (lotteryEventUuid) => {
  const hotelId = _get(store.getters['setting/getInit'], 'hotel_id', null)

  return `${getServerUrl()}/hotels/${hotelId}/lottery_events/${lotteryEventUuid}`
}
export const createLotteryResultURL = ({ hotelId }) =>
  `${getServerUrl()}/hotels/${hotelId}/lottery_results`

export const getLotteryResultURL = ({ entryNumber, hotelId }) =>
  `${getServerUrl()}/hotels/${hotelId}/lottery_results/${entryNumber}`

export const cancelLotteryResultURL = ({ entryNumber, hotelId }) =>
  `${getServerUrl()}/hotels/${hotelId}/lottery_results/${entryNumber}/cancel`

export const getMyPageLotteryResultURL = () => `${getServerUrl()}/user/lottery_results`

export const getMyVouchersURL = (params) => {
  const hotelId = _get(store.getters['setting/getInit'], 'hotel_id', null)
  return appendParams(`${getServerUrl()}/hotels/${hotelId}/memberships/discount_coupons`, params)
}

export const consumeVoucherURL = (voucherId) => {
  const hotelId = _get(store.getters['setting/getInit'], 'hotel_id', null)
  return `${getServerUrl()}/hotels/${hotelId}/memberships/discount_coupons/${voucherId}`
}

export const getRecommenderWidget = (code) =>
  `${getServerUrl()}/hotels/${code}/settings/recommender_widget`
