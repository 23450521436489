export default {
  "additionalQuestion": {
    "adultFemale": "女性",
    "adultMale": "男性",
    "answerQuestions": "请回答以下追加问题。",
    "checkinTime": "入住时间",
    "checkoutTime": "退房时间",
    "moreDetailed": "需要知道关于您的入住详情",
    "notificationFromFacility": "有关设施的注意事项",
    "selectTime": "时间选择",
    "view": "检视",
    "customizeYourStayExp": "客制化住宿体验",
    "customizeYourStayExpDesc": "请问可以回答几个额外的问题吗？"
  },
  "base": {
    "account": "账号",
    "all": "全部",
    "apply": "应用",
    "attachFile": "附加文件",
    "backTo": "返回{0}",
    "booking": "预约一览",
    "browserWarning": "目前使用的浏览器无法打开官方网站。请安装最新的浏览器版本，再尝试打开。",
    "cancel": "取消",
    "close": "关闭",
    "confirm": "确定预约",
    "continue": "下一步",
    "collapse": "缩小",
    "delete": "刪除",
    "edit": "变更",
    "error": "很抱歉，系统发生错误，请再试一次。",
    "error02": "不好意思,处理过程时系统发生错误, 请稍后再试。",
    "errorPaymentMethod": "因各房间选择了不同的支付方式，故无法继续预约程序。请选择同一种支付方式，或是分别预约。",
    "oldErrorCode": {
      "1403": "此账号已被设施注册，需要重设密码，请返回登录页面，点击“忘记密码”。"
    },
    "errorCode": {
      "1903": "可预约的期间为今天起 {reservable_period_months} 个月。\n请指定可预约期间的日期。",
      "2304": "此方案的设置与您的账户状态不符。请刷新页面并重新预订，敬请谅解。",
      "2401": "您只有{currentPoint}积分。无法使用{spendPoint}积分。",
      "3001": "发生超时错误。支付尚未处理。",
      "3002": "支付处理失败。",
      "3003": "此信用卡无法使用。",
      "3004": "请确认信用卡卡号。",
      "3005": "请确认有效日期是否正确。",
      "3006": "3D验证失败。",
      "3007": "信用卡输入错误或无法使用。",
      "3008": "3D验证已被取消。",
      "3009": "因多方要求，我们已经暂停3D验证。",
      "3011": "发生超时错误。支付尚未处理。",
      "3013": "由于此订单正在进行更新或处理付款问题，目前无法更改此预订内容，请在大约5分钟后再试。",
      "3014": "由于原本预订内容已更新，请取消并重新确认新内容后再进行预约变更。",
      "3202": "无法获得点数余额。",
      "3203": "不好意思,处理过程时系统发生错误, 请稍后再试。",
      "3204": "付款过程失败，请使用其他卡，或稍后重试。",
      "3205": "此信用卡无法使用。",
      "3250": "3D验证失败。",
      "3251": "不好意思,处理过程时系统发生错误, 请稍后再试。",
      "3252": "输入的卡不支援3D 验证。",
      "3502": "无法获得点数余额。",
      "3504": "点数无法返还。请确认点数记录。",
      "3750": "这些日期仅适用于抽奖活动。详情请查看酒店网站。",
      "3751": "已不提供此抽奖活动，详情请查看酒店网站。",
      "3752": "已不提供此抽奖活动，详情请查看酒店网站。",
      "3753": "已不提供此抽奖活动，详情请查看酒店网站。",
      "3754": "已不提供此抽奖活动，详情请查看酒店网站。",
      "3756": "您之前已参加了此次抽奖，请等待抽奖结果。如要确认参加纪录，可点击您会员资料页中的「预约内容」，在「抽奖」部分查看您的参与记录。",
      "3757": "已不提供此抽奖活动，详情请查看酒店网站。",
      "3760": "已不提供此抽奖活动，详情请查看酒店网站。",
      "3780": "您的链结已过期或无效，请重新查看酒店网站并再次参与抽奖活动。",
      "3781": "您的链结已过期或无效，请重新查看酒店网站并再次参与抽奖活动。",
      "3782": "您的链结已过期或无效，请重新查看酒店网站并再次参与抽奖活动。",
      "3783": "您的链结已过期或无效，请重新查看酒店网站并再次参与抽奖活动。"
    },
    "fileLimit80MB": "上传图片的上限为一张80MB",
    "upTo30FilesError": "不能附加超过 30 个文件",
    "upTo30Files": "最多30个文件",
    "textLengthLimit": "回答字数需在255字以内。",
    "triplaCoLtd": "tripla (株)",
    "triplaAi": "tripla.ai",
    "GDPR": "GDPR政策",
    "here": "此处",
    "imageError": "图片读取失败。",
    "join": "会员注册",
    "languages": {
      "en": "English",
      "ja": "日本語",
      "ko": "한국어",
      "zh_Hans": "簡体中文",
      "zh_Hant": "繁體中文"
    },
    "loading": "搜寻中",
    "login": "登录",
    "logout": "退出",
    "logoutMessage": "您已登出帐号",
    "signInStatusChangeMessage": "由于登入状态已变更，您将被重定向到搜寻画面。",
    "memberSignIn": "会员登入",
    "memberSignUp": "首次注册网上会员",
    "next": "下一步",
    "notSelected": "尚未选择",
    "noData": "没有数据",
    "optional": "选填",
    "pay": "支付",
    "previous": "上一页",
    "previousPage": "上一页",
    "privacyPolicy": "隐私条例",
    "readMore": "显示更多",
    "readLess": "隐藏",
    "recommended": "推荐",
    "remove": "删除",
    "required": "必填",
    "requireMessage": "必填栏位不能为空白。",
    "reservationStatuses": {
      "cancelled": "已取消",
      "done": "已预约"
    },
    "save": "保存",
    "signInUp": "登入/註冊",
    "submit": "提交",
    "termError": "请同意使用条款",
    "taxMessage": "现在显示的价格是税前价格加上8%的消费税。关于2019年10月后的预约，因为消费税会改变的关系，详细金额请参考预约确认画面的最终金额。",
    "termsOfUse": "使用条款",
    "topPage": "主页",
    "update": "更新",
    "success": "成功",
    "unsubscribe": "取消订阅",
    "unsubscribeConfirmation": {
      "error": "出现错误。请稍后再试。",
      "title": "取消订阅吗？",
      "description": "如果希望取消订阅促销电子邮件，请点击按钮。"
    },
    "unsubscribeSuccessDescribe": "您已取消订阅。我们不会再向您发送促销电子邮件。",
    "warning": {
      "kidsChanged": "因此设施不对应指定的搜索条件，关于儿童的搜索条件已变更。"
    }
  },
  "roomLottery": {
    "updateLottery": "变更抽奖申请",
    "restoreLottery": "復原抽奖申请",
    "updateDisabledDesc": "本次抽奖活动的参与期已结束，请等待结果。",
    "viewUpdateDetails": "查看/变更内容",
    "restoreUpdateEntry": "变更/復原内容",
    "updateProfileDesc": "当公开结果后，如您获奖及进行下一步预订时，上述提供的信息将被用于预约过程及无法更改。",
    "cancellationEmailDesc": "我们已发送取消电子邮件至 {0}",
    "cancelModalTitle": "取消抽奖申请",
    "cancelModalDesc": "您希望取消抽奖申请吗？此操作将无法撤回。",
    "cancelEntry": "取消申请",
    "reservationDeadlinePassed": "预订截止日期已过",
    "reservationDeadlinePassedDesc": "非常抱歉，但预订截止日期已过。关于详情，请浏览饭店网站或直接联系咨询。",
    "accountNotMatched": "此帐户与申请抽奖时所使用的帐户不符。请使用正确的帐户登入。",
    "resultDateNotice": "抽奖结果将于{date}（{day}）{time}～公布。",
    "resultDateNotice02": "只有获奖的人会收到电子邮件，其中包含如何预订的说明。",
    "resultDateNotice03": "您可以随时在会员选单的「预约内容」内查看状态。",
    "lotteryEntryNumber": "申请受理号码",
    "lotteryEntryConfirmed": "抽奖申请已确认",
    "lotteryEntryCancelled": "您的抽奖申请已取消",
    "lotteryResultNotSelected": "非常抱歉，您没有获奖。",
    "notSelectedNotice": "感谢您参加此活动，我们期待下次见到您。",
    "lotteryResultSelectedNotified": "🎉 您已被选为抽奖获胜者！",
    "lotteryWinnerNotice01": "※预订尚未确认。请完成预约程序。",
    "lotteryWinnerNotice02": "预约截止日期至 {date} ({day}) {time}",
    "manageYourLottery": "管理抽奖",
    "reservationIncomplete": {
      "errorMessage": "其他预订正在处理中。如果继续预订，所有其他预订都将被取消。",
      "checkboxMessage": "同意取消其他预订。",
      "modalMessage": "其他预订已处理完成，现在显示预约资讯。"
    }
  },
  "bookingDetail": {
    "overlappedReservation": "您预订的的入住日和退房日期与{0}重叠，请预订前再次确认。\n*重疊的預訂可能是此品牌下的其他設施。",
    "overlappedReservationLink": "之前的预订",
    "overlappedReservationGuest": "您预订的的入住日和退房日期与目前的预订重叠，请预订前再次确认。\n*重叠的预订可能是此品牌下的其他设施。\n*如果已使用其他设备取消预订，则此检测可能有误，请检查您收到的邮件进行确认。",
    "overlappedReservationGuest2": "*如果已使用其他设备取消预订或清除cookies，则此检测可能有误，请检查您收到的邮件进行确认。"
  },
  "bookingResult": {
    "allUser": "所有用户",
    "availableBetween": "有效期间",
    "bath": "浴室",
    "shower": "淋浴",
    "bathtub": "浴缸",
    "bedCount": "{n}张床",
    "book": "预约",
    "bookAirline": "附机票的预订",
    "flightRooms": "附机票的预订",
    "bookAirlineWarning": "{0}选项仅适用于{1}的入住期间。请调整入住日期才可使用此选项。",
    "bookAirlineWarningStay": "{0}选项仅适用于最长{1}天的住宿日数。请调整住宿日数才可使用此选项。",
    "cancellationFreeUntil": "{0}之前(设施当地时间) 都可以免费取消",
    "cancellationPolicy": "取消规定",
    "discountAmount": "折扣额度",
    "excludeDuring": "除外期间",
    "forNights": "住宿{n}晚",
    "freeWifi": "免费WiFI",
    "followingSearchConditions": "该日程没有空房。",
    "guests": "入住人数",
    "includes": "包括",
    "planInformation": "包括",
    "lowestPriceInfo": "根据空房状况和折扣条件，日历上可能无法显示最低价格。关于实际的价格，请确认日历。",
    "maxAdults": "可最多容納{n}位大人入住。",
    "meals": "餐点",
    "memberExclusive": "会员独享",
    "membersOnly": "会员限定",
    "nonSmoking": "禁烟",
    "noRoomFound": "非常抱歉，按您输入的条件，未能搜到有空房。",
    "tryChanging": "请尝试{0}或设施。",
    "tryItem": "其他日期",
    "noRoomSelected": "尚未选择客房",
    "noRoomsAvailable": "非常抱歉，按您输入的条件，未能搜到有空房。",
    "noRoomsAvailableDesc": "请尝试其他日期或设施。",
    "notAvailable": "无法预约",
    "priceFor": "价格适用于",
    "priceForAdult": "大人 × {n}",
    "priceForChild": "儿童 × {n}",
    "priceForRoom": "房间",
    "paymentCreditCard": "信用卡支付",
    "paymentMethods": "支付方式",
    "paymentMethod": {
      "on_site": "酒店当地支付",
      "credit_card": "信用卡支付",
      "on_site_credit_card_required": "现场支付（需事前输入信用卡）",
      "deferred_payment": "公司代付",
      "credit_card_full": "信用卡支付全额",
      "credit_card_deposit": "信用卡支付订金",
      "bank_transfer": "银行转帐",
      "credit_card_krw_domestic": "网上支付（韩国国内信用卡）",
      "credit_card_krw_international": "网上支付（国际信用卡）"
    },
    "paymentNewCreditCards": "以新的信用卡来支付",
    "paymentRegisteredCreditCards": "以登记过的信用卡来支付",
    "planDetails": "方案內容",
    "plansLimitForUpdating": "您无法选择变更最初所选择的住宿方案。",
    "price": "金额",
    "promotionMayChanged": "可能会变更或提早终止促销活动，恕不另行通知。",
    "registerCard": "登记此信用卡",
    "regularRate": "标准价格",
    "reservationStartDate": "预约开始日期",
    "showRoomDetails": "显示客房详情",
    "roomDetails": "客房详情",
    "roomFeatures": "客房详情",
    "roomLeft": "仅剩{n}间房！",
    "roomAvailable": "剩{n}间房",
    "roomEnough": "尚有空房",
    "roomRate": "价格",
    "rooms": "房间",
    "roomSize": "客房大小",
    "roomSizeUnit": {
      "tatami_mats": "榻榻米"
    },
    "select": "选择",
    "selectNewDate": "重新选择日期",
    "serviceFeeDesc": "代理预约网站的最终价格已含服务费。",
    "sleeps": "入住人数",
    "smoking": "吸烟",
    "stopReservationUpdate": "停止预约更新程序",
    "stopLotteryEntryRestore": "停止变更抽奖申请程序",
    "stopLotteryEntryUpdate": "停止復原抽奖申请程序",
    "targetUser": "目标用户",
    "taxExcluded": "不含税",
    "taxIncluded": "含税",
    "toilet": "洗手间",
    "totalPrice": "日元",
    "totalPriceForNights": "住宿{n}晚合计价格 | 住宿{n}晚合计价格 | 住宿{n}晚合计价格",
    "viewAll": "显示所有",
    "viewClose": "隐藏",
    "viewGallery": "查看所有图片",
    "viewLess": "隐藏",
    "viewMore": "显示所有的住宿方案 ({n}件)",
    "viewAllRooms": "查看所有{n}间客房",
    "wifi": "WiFi",
    "wifiOption1": "无WiFi",
    "wifiOption2": "免费",
    "wifiOption3": "付费",
    "searchResults": "搜寻结果:",
    "nPlans": "{n} 件",
    "nRoomTypes": "{n} 件"
  },
  "customAuth": {
    "failTitle": "登录失败",
    "failDesc": "因会员信息未注册，所以无法登录。"
  },
  "cancellationPolicy": {
    "policyHeader": "取消费详细如下,",
    "policyLine": "住宿日{0}日前:  {1}％ 的费用",
    "policyLineWithTime": "住宿日{0}日前{1}之后： {2}%的费用",
    "freePolicyLine": "住宿日{0}日前:  0％的费用",
    "noShowLine": "未入住且未联络:  {0}％的费用",
    "cancelOnTheDay": "住宿日当日:  {0}％的费用",
    "cancelOnTheDayFree": "入住当日{0}之前：免费取消",
    "cancelOnTheDayCharge": "入住当日{0}之后：{1}％的费用",
    "uponLocalTime": "所有的取消政策都基于预约设施的当地时间计算。",
    "uponLocalTimeOTA": "所有的取消政策都基于预约设施的当地时间计算。",
    "ifBookedElsewhere": "透过其他订房网站或旅行社订房的旅客请由该网站或者直接向旅行社谘询。",
    "specialRate": "特别取消率: {0}",
    "guest": "客人"
  },
  "calendar": {
    "fullyBooked": "全被订满",
    "roomsLeft": "剩余空房数",
    "selected": "住宿日期",
    "unavailable": "没有空房",
    "unavailableNow": "暂不可预订"
  },
  "corporateAdmin": {
    "admin": "管理者",
    "checkInDate": "入住日",
    "download": "下载",
    "guestName": "预约者姓名",
    "hotelName": "客房名",
    "maxNumberStay": "最多住宿天数",
    "maxNumberStays": "最多住宿天数",
    "nMonths": "{n}个月",
    "nights": "入住天数",
    "numberOfRooms": "房间",
    "remaining": "还可预约的天数",
    "reservationData": "预约资讯",
    "reservationDataDesc1": "※使用详情仅显示透过本官网预约的数目的合计。所以，透过其他渠道（电话等）的预约将不会显示，敬请注意。",
    "reservationDataDesc2": "※预约・住宿完成后，将会需要数分钟~数十分钟的时间在本官网显示，敬请见谅。",
    "reservationDataDesc3": "※此画面将不会显示透过非本官网的其他渠道进行的住宿内容变更及取消。",
    "reservationDate": "预约日期",
    "reservationNumber": "预约号码",
    "reserved": "已经住宿天数",
    "status": "状态",
    "title": "企业管理者",
    "totalPrice": "价格",
    "period": "期间",
    "year": "年"
  },
  "creditCardForm": {
    "enterYourPaymentDetails": "输入付款信息",
    "addNewCard": "输入新的信用卡",
    "cardExpiration": "有效期限",
    "cardExpirationConfirm": "请确认信用卡有效期限",
    "cardHolder": "信用卡持有者姓名",
    "cardHolderConfirm": "请确认信用卡持有者姓名",
    "cardHolderFormatError": "请用拼音字母输入姓名。",
    "cardHolderHelpOTA": "输入您的全名（与信用卡上一致）",
    "cardHolderHelp": "输入您的全名（与信用卡上一致）",
    "cardNumber": "信用卡卡号",
    "cardNumberConfirm": "请确认信用卡卡号",
    "cardNumberHelp": "输入您的信用卡卡号",
    "cardRequired": "信用卡的信息仅是为了确保预约，我们不会从此信用卡扣款。但是若是依照{0}产生取消费用的情况，则会从此卡扣取。",
    "cardSecurityCode": "安全码（CVV)",
    "cardSecurityCodeConfirm": "请确认输入的安全码",
    "cardSecurityCodeHelp": "CVV",
    "ccvExampleOTA": "列於（Visa、Mastercard、JCB 和 Diners Club）卡背面或（American Express）卡正面的3或4位数字。",
    "ccvExample": "印在信用卡背面（Visa、Mastercard、JCB）或正面（American Express）的3位或4位数字。用于验证使用者是否合法持卡人，以及防止在线购物时的诈骗事件。",
    "creditCard": "信用卡",
    "expiredError": "请输入在退房当日有效的信用卡。",
    "month": "月",
    "payInAdvanceOTA": "若是经由{0}的预约，请使用信用卡事前支付费用。",
    "payInAdvance": "若是经由{0}的预约，请使用信用卡事前支付费用。",
    "payInAdvanceVoucher": "若是经由{0}的预约，请使用信用卡事前支付费用。",
    "payByCreditCard": "完成预订时，将由旅行代理店 ({0}) 付款。",
    "payByCreditCardConsent": "您的信息将被妥善分享给旅行代理公司tripla株式会社以完成预订。继续此预订流程即表示您同意{0}、{1}以及{2}的相关条款。",
    "tappayNote": "我们使用TapPay 2048-bit SLL支付系统。 我们不会保存任何持卡人的信息。TapPay透过遵守Service Provide Level 1所认证的PCI DSS,来保护您的信用卡资讯。",
    "year": "年",
    "cardBrand": "信用卡组织",
    "cardBrandMismatch": "请输入正确的卡号或选择正确的信用卡组织",
    "paymentOverMillionError": "在线支付的金额超过100万日元则无法使用 VISA卡 和Master卡。请使用其他信用卡类別或选择其他付款方式。",
    "badCards": "由于系统变更，新的预订无法使用以下的卡。非常抱歉，请重新输入卡的信息。",
    "gmoBadCards": "由于系统变更，我们目前暂时不支援可保存信用卡资讯的功能。每次使用信用卡支付时都需要重新输入信用卡资讯。造成您的不便, 我们深感抱歉, 也非常感谢您的配合与谅解。",
    "pontaVisaCheckbox": "使用Route Inn Hotels PontaVISA卡时，请选择「Route Inn Hotels PontaVISA卡」作为卡片品牌。如果您选择「VISA」，则不会获得奖励积分。",
    "creditCardInvalid": "无效",
    "creditCardInvaildHoverText": "由于系统变更，新的预订无法使用以下的卡。非常抱歉，请重新输入卡的信息。",
    "rrn": "出生日期（YYMMDD）",
    "firstTwoDigitOfPin": "PIN 码的前两位数字"
  },
  "gmoPaypalOptions": {
    "null": "请选择信用卡组织",
    "visa": "VISA",
    "ponta_visa": "VISA (Route Inn Hotels PontaVISA card)",
    "master": "Mastercard",
    "mastercard": "Mastercard",
    "amex": "American Express",
    "jcb": "JCB",
    "diners": "Diners Club"
  },
  "downloadPasswordModal": {
    "label": "为了让信息加密，请输入密码。输入此密码，下载的zip文件才可解压。"
  },
  "extraItems": {
    "error": {
      "extraNumber": "请变更{name}的数目。可能预约的数目为{min}〜{max}。"
    }
  },
  "facilitySuggestion": {
    "distanceFrom": "距离：距此设施{n}千米",
    "from": "最优惠价格",
    "header": "附近可入住的设施",
    "soldOut": "搜索不到空房。请更改条件，重新搜寻。",
    "soldOutDesc": "非常抱歉，按您搜索的条件，未找到空房。",
    "visitHotelWebsite": "打开设施的页面"
  },
  "guestDetail": {
    "afterUpdate": "更改后",
    "bookingForSomeone": "为他人预订酒店，非本人亲自入住",
    "brrDiscount": "最优惠房价折扣",
    "confirmUpdates": "确定变更预约",
    "confirmPayByCard": "确定以信用卡支付",
    "coupon": "优惠码",
    "couponApplied": "已使用优惠码{code}。",
    "couponAppliedNew": "适用的优惠券：",
    "creansmaerdCouponApplied": "已使用优惠码{name}。",
    "couponCode": "优惠代码",
    "couponDiscount": "优惠码折扣",
    "couponExpired": "非常抱歉，此优惠码已不再有效。",
    "couponInvalid": "优惠码输入错误。",
    "couponLimitReached": "因使用上限的原因，我们不再提供此优惠码 {code}。",
    "couponMaxStays": "此优惠码的折扣仅适用于最开始的{n}晚。之后的天数按普通价格预约。",
    "couponTargetPlans": "优惠码适用方案",
    "couponNotUse": "不使用优惠码",
    "couponValidUntil": "有效期至 {date}",
    "couponUsageQuantityLimit": "一次只可使用一个优惠券代码。请删除已添加的才可使用新的代码。",
    "creditCardWarning": "原本价格, 即最初预约的费用，会退还至您的信用卡，并以变更完成后的总价, 即最新价格重新付费。",
    "day": " 第 {n} 天",
    "email": "邮箱地址(请指定可接收{email})",
    "emailAgain": "邮箱地址（确认用）",
    "emailAgainConfirm": "两次输入的电子邮箱不一致。",
    "emailAgainHelp": "请再次输入电子邮箱。",
    "emailConfirm": "请输入正确的电子邮箱。",
    "emailHelp": "请输入电子邮箱。",
    "extras": "加购项目",
    "extraLeft": "剩 {count} {unit}",
    "extraOutOfStock": "沒有庫存",
    "fullName": "姓名",
    "fullNameError": "请输入姓名的拼音。",
    "goPreviousScreenConfirm": "返回上一个画面？",
    "goPreviousScreenDescription": "请注意，在此页面上输入的所有资讯都没有储存。",
    "goPreviousScreenContinue": "继续",
    "guestDetails": "顾客详情",
    "guestName": "顾客姓名",
    "guestNameError": "请输入姓名的拼音。",
    "guestNameHelp": "请输入姓名",
    "guestNameKanji": "住宿者姓名(汉字)",
    "guestStayName": "客人姓名",
    "mainGuest": "主要联系人",
    "notes": "备注",
    "originalPaymentAmount": "原本价格",
    "promotionDiscount": "折扣",
    "phone": "电话号码",
    "phoneConfirm": "请提供正确的电话号码",
    "phoneHelp": "请输入电话号码",
    "priceBecomeCheaper": "由于促销活动内容会根据住宿设施的不同而有所变更，目前选择的住宿方案的价格比之前更加优惠。请确认现在的价格，然后进行预约。",
    "priceBecomeExpensive": "非常抱歉，由于促销活动内容会根据住宿设施的不同而有所变更，目前选择的住宿方案的价格比之前有所提高。请确认现在的价格，然后进行预约。",
    "priceDiff": "差价",
    "signupTerms": "本预约系统为tripla(株)提供, 顾客的个人资讯会与tripla(株)共享。我同意tripla{0}、{1}以及{2}。",
    "terms": "提供此预订系统的tripla(株)将共享您的个人资讯。本预订完成后, 即表示已经同意tripla{0}、{1}和{2}。",
    "totalAmountWithTax": "合计金额（含税）",
    "totalWithoutTax": "合计金额（不含税）",
    "updateUserInformation": "更新以上除电子邮件以外关于会员信息的修改。",
    "cheaperAccommodationTax": "由于兌换了点数，预订的住宿稅也减少了。请在预订前查看最新住宿费。",
    "roomGuestName": "房间 {0} 顾客姓名"
  },
  "inventoryCalendar": {
    "availableIconDescription": "{icon}：有空房",
    "unavailableIconDescription": "{icon}：没有空房",
    "uncertainIconDescription": "{icon}：剩余少量"
  },
  "lineLogin": {
    "buttonText": "使用LINE帐号登入"
  },
  "meta": {
    "facilities": "品牌设施",
    "sign_in": "登录",
    "organization_sign_in": "企业或组织登入页面",
    "sign_up": "会员注册",
    "search_results": "住宿方案一览表",
    "guest_detail": "输入顾客资讯",
    "payment_method": "输入付款方法",
    "lotteryCompleted": "完成抽奖申请",
    "completed": "完成预约",
    "reservationIncomplete": "未完成的预约",
    "my_account": "我的页面",
    "otaHome": "AI智选您的理想饭店",
    "otaHomeDescription": "tripla提供2000多个精选住宿，支持AI搜索。从传统的旅馆到豪华度假胜地，轻松找到您完美的住宿。",
    "otaSearchHotels": "酒店搜寻结果",
    "otaSearchResults": "住宿方案一览表",
    "otaGuestDetail": "输入顾客资讯",
    "otaPayment": "输入付款方法",
    "otaConfirmation": "完成预约",
    "otaBookingConfirmation": "预约确认"
  },
  "membership": {
    "promotionRate": "促销折扣",
    "regularRate": "标准价格",
    "signUpFor": "会员价格"
  },
  "membershipForm": {
    "address": "地址",
    "alreadyHaveAccount": "已有帐号",
    "back": "上一页",
    "buildingName": "建物名称与房号",
    "changePassword": "更改密码",
    "changePasswordResult": "重新设定密码",
    "changePasswordResult02": "✓ 顾客的密码已重新设定。",
    "changePasswordResult03": "请点此再次登录。",
    "changePasswordSecondary": "请输入您的新密码",
    "confirmDeletedResult": "您已经成功退会。",
    "confirmNewPassword": "确认新密码",
    "confirmPassword": "确认密码",
    "confirmRegisterFail": "登入失败",
    "confirmRegisterFail02": "请再次创建账号。",
    "confirmRegisterResult": "电子邮箱认证",
    "confirmRegisterResult02": "✓ 已成功创建",
    "confirmRegisterResult03": "尽情享受住宿的时光",
    "createAccount2": "会员创建",
    "createAccount3": "会员创建",
    "dob": "出生日期",
    "email": "电子邮箱",
    "emailError": "请确认您的邮箱地址。",
    "emailPromotionCheck": "接收来自酒店的促销邮件。",
    "english_error": "请输入英文拼音",
    "locale_error": {
      "ja": "请输入英文拼音",
      "zh_Hant": "请输入英文拼音",
      "ko": "请输入英文拼音"
    },
    "enterDetail": "请输入您的资料",
    "first_name": "名字(英文拼音)",
    "firstNameLocales": {
      "ja": "名字(英文拼音)",
      "zh_Hant": "名字",
      "ko": "名字(英文拼音)"
    },
    "first_name_kanji": "",
    "first_name_kanji_placeholder": "",
    "first_name_placeholder": "Dawen",
    "firstNamePlaceHolderLocales": {
      "ja": "Dawen",
      "zh_Hant": "Da Ming",
      "ko": "Dawen"
    },
    "firstName": "名字",
    "firstNameError": "请输入您的名字。",
    "firstNameKana": "",
    "forgotPassword": "忘记密码",
    "forgotPassword02": "重新设定密码",
    "forgotPasswordError": "您输入的电子邮箱尚未注册会员。",
    "forgotPasswordResult": "重新设定密码",
    "forgotPasswordResult02": "✓ 已发送重新设定密码的链接至您的电子邮箱。",
    "forgotPasswordResultSecondary": "发送的邮件可能需要数分钟的时间接收，若10分钟后任未收到，请点击下列再次发送的按钮。",
    "forgotPasswordSecondary": "请输入您的电子邮箱，我们会发送重新设定密码的链接。",
    "gender": "性別",
    "generalError": "不好意思,处理过程时系统发生错误, 请稍后再试。",
    "guest_first_name": "名字(英文拼音)",
    "guest_first_name_kanji": "",
    "guest_last_name": "姓氏(英文拼音)",
    "guest_last_name_kanji": "",
    "header": "会员创建",
    "hiragana_error": "",
    "kanji_katakana_error": "",
    "kanji_error": "",
    "last_name": "姓氏(英文拼音)",
    "lastNameLocales": {
      "ja": "姓氏(英文拼音)",
      "zh_Hant": "姓氏",
      "ko": "姓氏(英文拼音)"
    },
    "last_name_kanji": "",
    "last_name_kanji_placeholder": "",
    "last_name_placeholder": "Chen",
    "lastNamePlaceHolderLocales": {
      "ja": "Chen",
      "zh_Hant": "Wang",
      "ko": "Chen"
    },
    "lastName": "姓氏",
    "lastNameError": "请输入您的姓氏。",
    "lastNameKana": "",
    "membershipId": "会员号码",
    "membershipProgram": "会员制度",
    "name": "名字",
    "nameJaPlaceholder": "",
    "nameJaValidation": "",
    "nameInvalidCharMessage": "您输入的字符包含此系统无法支援的字符。",
    "nameKanaJaPlaceholder": "",
    "namePlaceholder": {
      "en": "请输入英文",
      "ja": "请输入平假名或英文",
      "zh_Hant": "请输入汉字或英文",
      "zh_Hans": "请输入汉字或英文",
      "ko": "请输入韩文或英文"
    },
    "nameValidation": {
      "ja": "请输入平假名或英文",
      "zh_Hant": "请输入汉字或英文",
      "zh_Hans": "请输入汉字或英文",
      "ko": "请输入韩文或英文"
    },
    "nationality": "国籍",
    "newPassword": "新密码 (请输入8个或以上的字符, 必须包含大写和小写英文字母、数字及符号。)",
    "noAccount": "希望成为我们的会员吗?",
    "occupation": "职业",
    "or": "或",
    "orContinueBelow": "或输入下方资料",
    "password": "密码",
    "passwordConfirmationError": "密码与确认密码不吻合",
    "passwordError": "请确认您的密码。",
    "passwordSignUp": "密码 (请输入8个或以上的字符, 必须包含大写和小写英文字母、数字及符号。)",
    "passwordNote": "请输入8个或以上的字符, 必须包含大写和小写英文字母、数字及符号。",
    "pleaseSelect": "選擇會員制度",
    "postCode": "邮递号码",
    "postCodeError": "仅可输入英文字母和数字。",
    "prefecture": "省份",
    "notApplicable": "不适用",
    "privacyPolicy": "隐私权政策",
    "promotionsCheckbox": "我想收到email的特别优惠和促销讯息",
    "resend": "再次发送",
    "resendConfirmationEmail": "未收到会员注册确认邮件的情况",
    "resendConfirmationEmailError": "此电子邮箱已经被注册。",
    "resendConfirmationEmailTitle": "再次发送会员注册确认邮件",
    "receiptName": "收据抬头",
    "receiptUnicode": "",
    "send": "发送",
    "setPassword": "更改",
    "setup": "会员创建",
    "setupResultSecondary01": "如果您想要再次尝试注册会员，请点击{0}。",
    "setupResultSecondary02": "已寄送确认邮件至登录的电子邮箱。\r\n目前为暂时登录的状态。请点击邮件中的URL以完成会员登录的手续。\r\n登录完成后即可登入。",
    "signIn": "登录",
    "signInHere": "登入",
    "signInTo": "登录{0}",
    "signUp": "创建会员账号",
    "signUpResult": "已发送确认邮件。",
    "signUpResult02": "已成功创建",
    "signUpResult02Secondary": "感谢您申请成为我们%{hotel_name}的会员。",
    "signUpResult02Secondary02": "A confirmation email has been sent to the email address you supplied.",
    "signUpResultSecondary": "感谢您申请成为我们%{hotel_name}的会员。",
    "signUpResultSecondary02": "为了完成创建手续，请查看确认邮件。",
    "signUpResultSecondary03": "请点击邮件中的链接，",
    "signUpResultSecondary04": "完成创建会员账号的手续。",
    "signUpSecondary": "仅需填写以下资料，即可立即创建账号",
    "term": "我同意{0}及{1}。",
    "termError": "Please accept to the terms and conditions",
    "termOfUse": "使用规则",
    "wifi": "Free in-room wifi",
    "yetReceived": "没有收到确认邮件？{0}",
    "resendEmail": "按此处再发送",
    "pleaseResetPassword": "您所输入的电子邮件帐号的註册已经确认，所以无需重新发送确认电子邮件。\n如果您知道密码，请{0}。\n如果您忘记了密码，请使用{1}选项。",
    "pleaseResetPasswordLink1": "直接登入",
    "pleaseResetPasswordLink2": "密码重置",
    "receiptNameFormatError": "",
    "receiptUnicodeFormatError": "",
    "receiptNameRequiredError": "",
    "receiptUnicodeRequiredError": ""
  },
  "membersihp": {
    "target": "适用会员"
  },
  "multipleCurrency": {
    "JPY": "日元",
    "ARS": "阿根廷比索",
    "AUD": "澳元",
    "BRL": "巴西雷阿尔",
    "CAD": "加元",
    "CNY": "人民币",
    "CZK": "捷克克朗",
    "DKK": "丹麦克朗",
    "EUR": "欧元",
    "HKD": "港币",
    "INR": "印度卢比",
    "IDR": "印尼卢比",
    "ILS": "以色列新谢克尔",
    "MOP": "澳门元",
    "MYR": "马来西亚令吉",
    "MXN": "墨西哥比索",
    "TWD": "新台币",
    "NZD": "新西兰元",
    "NOK": "挪威克朗",
    "PHP": "菲律宾比索",
    "GBP": "英镑",
    "QAR": "卡塔尔里亚尔",
    "RUB": "俄罗斯卢布",
    "SAR": "沙特阿拉伯里亚尔",
    "SGD": "新加坡元",
    "ZAR": "南非兰特",
    "KRW": "韩元",
    "LKR": "斯里兰卡卢比",
    "SEK": "瑞典克朗",
    "CHF": "瑞士法郎",
    "THB": "泰铢",
    "AED": "阿联酋迪拉姆",
    "USD": "美元",
    "VND": "越南盾",
    "modalTitle": "选择货币",
    "modalDescription": "请选择一种货币。如果您选择日元以外的货币，您的付款方式将仅限於信用卡。",
    "suggestedCurrencies": "常用货币",
    "allCurrencies": "所有货币",
    "creditCardWarning": "由于变更付款方法，付款货币同时有更改。请确认金额。",
    "exchangeRateUpdatedError": "汇率已更新，请查看最新价格并预订。",
    "currency": "货币"
  },
  "myAccount": {
    "availablePoints": "可使用{0}",
    "availablePointsDesc": "目前持有和可使用的{0}。",
    "totalRankingPoints": "您持有{amount}等级{pointName}。",
    "changeLoginEmail": "变更电子邮箱",
    "changeLoginEmailDescription": "输入新的电子邮箱后,确认邮件就会发送至该邮箱。点击邮件中的URL，即可完成变更。若邮箱地址输入错误，需再次更改，确认邮件会发送至最新更改的邮箱地址。",
    "changeLoginEmailPendingText": "目前还在确认新的电子邮箱。请点击更新时发的邮件中的URL查看状态。",
    "changePassword": "重新设定密码",
    "confirmLoginEmail": "新的电子邮箱（确认中）",
    "edit": "编辑",
    "emailLabel": "邮箱地址",
    "loginEmail": "邮箱地址",
    "loginInfo": "登录信息",
    "notRegistered": "未有信用卡登录",
    "pageTitle": "账号内容",
    "password": "密码",
    "passwordLabel": "密码",
    "paymentInfo": "支付信息",
    "paymentOptions": "支付方法",
    "paymentOptionsDesc": "为了使您今后的支付手续更加快捷方便，您可以保存您的信用卡信息。所有的信用卡相关信息会进行严格的保密。",
    "pendingEmailText": "{n} 电子邮箱确认中",
    "personalInfo": "个人信息",
    "promotions": "促销活动",
    "point": "点数",
    "removeAccount": "退会",
    "removeAccountConfirmationText": "是否确认退会？",
    "removeAccountReason01": "不会再次使用。",
    "removeAccountReason02": "住宿费太贵。",
    "removeAccountReason03": "对会员优待不感兴趣。",
    "removeAccountReasonInput": "请在此输入理由",
    "removeAccountReasonOthers": "其他",
    "removeAccountSecondaryText": "退会后所有的会员优待将无效。",
    "removeAccountSecondaryText02": "预约消息也将不会显示。",
    "removeCreditCard": "删除信用卡",
    "removeCreditCardConfirm": "您确定要删除登录的信用卡吗？",
    "resendEmail": "再次发送确认邮件",
    "resendEmailSuccess": "已经再次发送确认邮件。",
    "yourCreditCards": "已登录信用卡",
    "membershipId": "会员编号",
    "myQRCode": "我的二维码",
    "promotion": "促销",
    "historyStatus": {
      "expired": "已过期的点数",
      "future": "将获得的点数",
      "spent": "已使用的点数",
      "gained": "获得的点数",
      "refunded": "退还的点数"
    },
    "value": "价值",
    "badCards": "由于系统变更，新的预订无法使用此卡。",
    "unlockRank": "在{date}之前赚取{points} {pointName}，便可达到{rank}等级。",
    "unlockRankNoDate": "只要赚取{points} {pointName}，便可达到{rank}等级。",
    "earnMorePointsToKeepRank": "要保持目前的{rank}等级，您必须在{date}之前赚取{points} {pointName}。",
    "achievedHighest": "恭喜！您已获得最高等级。",
    "achievedHighestOnDate": "恭喜！您已达成条件，并将在{date}获得最高等级。"
  },
  "myProfile": {
    "cancel": "取消您的预约",
    "cancelled": "已取消",
    "change": "更改",
    "checkIn": "入住日期",
    "checkOut": "退房日期",
    "confirmationNo": "预约号码",
    "multiRoom": "多种房型",
    "myReservations": "预约内容",
    "myPage": "个人頁面 ",
    "noResultButtonText": "查找空房",
    "noResultText": "您尚未预约客房",
    "pageTitle": "帐户情报",
    "viewDetails": "显示详细内容",
    "viewFlightDetails": "确认附机票预订的详情",
    "lottery": {
      "status": {
        "registered": "进行中",
        "not_winner": "不获奖",
        "winner_reservation_confirmed": "获奖(已预订)",
        "winner_reservation_cancelled": "预订已取消",
        "registration_cancelled": "抽奖已取消",
        "winner_reservation_pending_declined": "抽奖已取消",
        "winner_reservation_pending": "获奖(未预订)"
      },
      "text": "抽奖"
    },
    "myVoucher": {
      "amount": "金额",
      "askConsume": "使用兌换券吗?",
      "askConsumeMessage": "此优惠券只能使用一次。",
      "cancel": "取消",
      "consume": "使用",
      "consumeVoucher": "使用兌换券",
      "excludeDuring": "除外期间",
      "makeReservation": "预订",
      "targetStayingPeriod": "适用的入住期间",
      "validThrough": "适用的贩卖期间",
      "name": "名称",
      "consumptionTime": "使用时间",
      "reservationNumber": "预约号码",
      "status": {
        "status": "状态",
        "available": "可使用",
        "pending": "保留中",
        "expired": "已过期",
        "consumed": "已使用"
      }
    },
    "processBooking": "进行预订",
    "cancelEntry": "取消申请",
    "lotteryEntryNo": "申请受理号码"
  },
  "organization": {
    "enterDetail": "请输入帐户ID及密码",
    "signIn": "企业或组织登入页面"
  },
  "ota": {
    "changeLoginEmailPendingText": "目前还在确认新的电子邮箱。请点击更新时发的邮件中的URL查看状态。",
    "name": {
      "agoda": "Agoda",
      "booking_com": "Booking com",
      "expedia": "Expedia",
      "jalan": "Jalan",
      "rakuten_travel": "Rakuten Travel",
      "rakutenTravel": "Rakuten Travel"
    },
    "priceNotAvailable": "暂未提供",
    "loadingTitle0": "探索完美住宿体验",
    "loadingDescription0": "AI客服为您推荐最适合饭店。您的梦想住宿上传中...",
    "loadingTitle1": "为您的日本体验升级",
    "loadingDescription1": "一起沉浸於丰富的日本文化，体验传统的茶道，享受抹茶制作艺术，从中品味宁静。",
    "loadingTitle2": "期待的神秘景点",
    "loadingDescription2": "闯入鲜为人知的社区，探索人迹罕至的奇观。一起发现当地宝藏，感受这座城市的真正魅力。",
    "loadingTitle3": "联系传统",
    "loadingDescription3": "请感受日本艺妓者的魔力。透过观赏传统舞蹈表演或体验茶道，感受日本文化。",
    "loadingTitle4": "美食佳肴",
    "loadingDescription4": "参观人气的美食市场，深入了解日本料理的多元化。从品尝壽司的鲜味至香喷可口的章鱼烧，满足您的味蕾。",
    "loadingTitle5": "大自然的宁静",
    "loadingDescription5": "逃离城市的喧嚣，漫步迷人的竹林。体验这座历史名城的宁静和永恒之美。",
    "loadingTitle6": "樱花盛况",
    "loadingDescription6": "樱花满开时来一趟日本! 感受被樱花染成一片粉红的壮丽景观，参与当地的花见庆祝活动，享受一趟奇妙之旅。",
    "fromBwToOtaPaymentError": "出现错误。请按「下一步」按钮或选择不同的付款方式重试。",
    "fromBwToOtaRestoreDataError": "出现错误。请重新输入资料并再次按「下一步」按钮。",
    "searchHotels": "搜寻酒店",
    "aiRecommendations": "AI住宿推荐",
    "recommendationsForU": "您的最佳住宿",
    "interestedIn": "你可能也会对這些感兴趣...",
    "home": "主页",
    "showingCountAvailable": "正显示{total}中的{count}酒店清单",
    "standardTravelAgency": "旅行业条款",
    "termsOfUse": "用户条款",
    "privacyPolicy": "隐私政策",
    "companyProfile": "公司简介",
    "bookingSupport": "联系我们",
    "search": "搜寻酒店",
    "checkAvailability": "查看空房",
    "avgPricePerNight": "每晚参考房价",
    "adults": "大人{n}名",
    "GDPR": "GDPR 保护规范",
    "homepage": {
      "recommend1": "周末想找一家在东京经济实惠的酒店。",
      "recommend2": "帮我找下冲绳的度假酒店，3位。",
      "recommend3": "想找一家京都旅馆为妻子庆祝生日。",
      "recommend4": "下个月要前往神户出差，有没有推荐的一人住酒店？",
      "placeholder": "例：我想要在新宿附近的酒店预订一个双人房间,三晚。"
    },
    "bookingConfirmation": "预约确认",
    "confirmToOtaTitle": "预订确认流程",
    "confirmToOtaDescription": "接下来，我们将引导您前往旅行代理店的官方网站({0})，在那里完成预付款操作后，您的预订将正式成立。",
    "confirmToOtaDescriptionInGuestForm": "接下来，我们将引导您前往旅行代理店的官方网站({0})，在那里完成预付款操作后，您的预订将正式成立。",
    "aiSearchNoResult": "我们的AI正在学习中，无法根据您输入的关联词提供建议，请提供更多详细信息，例如地点、人数、设施资讯等。此外，以下的建议您可能感兴趣。"
  },
  "otaRoomsResult": {
    "noRoomsAvailable": "非常抱歉，您输入的条件没有空房。",
    "noRoomsAvailableDesc": "请选择其他日期，或更改入住人数后再次搜寻是否有适合客房。",
    "location": "位置",
    "showAllPhotos": "显示所有图片"
  },
  "otaRoomsMap": {
    "title": "地图",
    "seeMap": "查看地图"
  },
  "paymentMethod": {
    "paymentKRWCreditCardFooterTitle": "线上付款由以下公司处理：",
    "paymentKRWCreditCardFooterLine1": "Tripla Co., Ltd.（韩国分公司）",
    "paymentKRWCreditCardFooterLine2": "商业登记号码：538-84-00072",
    "paymentKRWCreditCardFooterLine3": "3F Concordian Building, 76 Saemunan-ro, Jung-gu, Seoul, South Korea (P. 03185)",
    "paymentKRWCreditCardFooterLine4": "support_ko{'@'}tripla.io",
    "paymentKRWCreditCardFooterLine4Link": "mailto:support_ko{'@'}tripla.io",
    "paypalCheckbox1": "如果信用卡公司不支援3D Secure，则无法使用。",
    "paypalCheckbox2": "基於发卡公司需要检测或者防止非法使用信用卡的事件，本公司从客户收集的个人信息等将提供给客户使用的发卡公司。",
    "paypalCheckbox3": "姓名、电话号码、电子邮箱、互联网使用环境的信息等。",
    "paypalCheckbox4": "如果客房所使用的发卡公司在日本国外，这些信息可能会转移到发卡公司所属的国家。",
    "paypalCheckbox5": "如果客户为未成年人士，请在父母或者法定监护人的同意下使用本服务。",
    "pre_paid": "信用卡支付",
    "credit_card": "預結算",
    "payments": {
      "credit_card": "信用卡支付"
    },
    "deferred_payment": "公司代付",
    "on_site": "当场支付",
    "on_site_credit_card_required": "当场支付",
    "type": "支付方式",
    "twd_credit_card": "信用卡支付全额",
    "twd_credit_card_deposit": "信用卡支付订金",
    "bank_transfer": "银行转帐",
    "paymentPromotion": "付费促销",
    "paymentPromotionTooltip": "可能会变更或提早终止促销活动，恕不另行通知。",
    "undefined": ""
  },
  "pointHistory": {
    "availableAt": "有效使用",
    "cancellation": "取消",
    "dateColumn": "日期",
    "descriptionColumn": "详细内容",
    "failToLoadErrorMessage": "很抱歉，点数纪录载入失败",
    "noData": "您目前没有任何点数的纪录",
    "pointExpirationColumn": "点数失效期限",
    "pointHistory": "点数纪录",
    "pointsColumn": "点数",
    "pointUse": "使用点数",
    "stay": "住宿",
    "viewMore": "显示更多",
    "exchangeDigico": "{point}转换",
    "exchangeDigicoDesc": "请输入您希望转换为Digico点数的{point}数目。Digico点数可转为用于Amazon、Apple、Google的点数。一经转换将无法为您复原。转换后的Digico点数仅有180天的有效期。此服务仅可在日本进行。转换后，设施将无法回答关于Digico点数的问题。关于Digico点数的更多详情，请查看{link} 。",
    "exchangeDigicoLink": "点此",
    "exchangeDigicoLog": "Digico点数转换",
    "exchangeAvaliablePoints": "可转换的{point}合计",
    "exchangeBtn": "转换",
    "exchangeHistory": "{point}转换",
    "exchangedAmount": "转换金额",
    "exchangedDigicoCode": "代码",
    "exchangeConfirm": "一经转换将无法为您复原。您确定希望转换{point}吗？",
    "exchangeConfirmBtn": "继续",
    "digicoPointUrl": "Digico点数转换URL：",
    "invalidAmount": "请输入有效的点数数。",
    "totalBalance": "总共点数",
    "currentPoints": "可用的点数",
    "futurePoints": "将获得的点数"
  },
  "pointSpending": {
    "balance": "可使用的点数",
    "errors": {
      "incorrectAmount": "如要使用点数,需以每{n}点来使用。",
      "moreThanPrice": "使用的点数无法超过所需的付款金额。",
      "upToBalance": "您输入的点数已超出持有的额度, 请修改。",
      "pointUsageError": "总付款金额若为0，是无法进行信用卡支付的。为了启用在线支付，您可以在上一步减少点数的使用。"
    },
    "minimumAmount": "使用点数时, 最低需使用{0}点数。",
    "points": "分",
    "saveForLater": "这次无法使用点数",
    "updateRule": "您无法使用已用于原来预约的点数。我们会在更新预约后返还点数。",
    "useAll": "使用全部的点数",
    "useAmount": "使用部分点数",
    "title": "点数"
  },
  "points": {
    "naming": {
      "default": "点数",
      "history": "{point}履歴",
      "available": "可使用{point}",
      "future": "将获得{point}",
      "expired": "已过期{point}",
      "gained": "获得{point}",
      "spent": "已使用的{point}",
      "save": "保存{point}以备后用",
      "redeem": "兌换全部{point}",
      "refunded": "退还的{point}",
      "miniSpending": "最少使用{point}必须为 {number} 或更多。",
      "useIncrements": "您可以以{number}递增使用{point}",
      "discount": "{point} 优惠",
      "earn": "预订此房间可获得{number} {point}",
      "checkOut": "{point}将在退房日的3天后添加。",
      "usePoint": "使用{point}",
      "empty": "目前没有任何{point}记录。",
      "total": "总共",
      "availableBalance": "可使用的{point}",
      "redeemRemark": "使用部分{point}",
      "cannotUse": "您无法使用已用于原来预约的{point}。我们会在更新预约后返还{point}。"
    }
  },
  "progressBar": {
    "completeLottery": "完成抽奖申请",
    "completeLotterySubtitle": "您已完成抽奖申请",
    "confirmationSubtitle": "您的房间已经预约",
    "confirmationSubtitleOTA": "您的房间已经预约",
    "confirmationTitle": "预约完成",
    "confirmationTitleOTA": "完成预约",
    "guestSubtitle": "请填写您的详情",
    "guestTitle": "顾客详情",
    "paymentSubtitle": "选择支付方式",
    "paymentTitle": "支付"
  },
  "reservation": {
    "cancelled": "已取消",
    "completed": "已完成",
    "upcoming": "即将到来",
    "lottery": "抽奖",
    "filtersLabel": "选择条件",
    "filters": {
      "all": "全部",
      "onSiteChecked": "酒店当地支付",
      "on_site_credit_card_required": "酒店当地支付",
      "otaPrePaidChecked": "{0} 信用卡付款",
      "bookingWidgetPrePaidChecked": "信用卡支付",
      "deferredPaymentChecked": "公司代付"
    },
    "reservedChannel": {
      "all": "全部",
      "title": "预约渠道",
      "officialWebsite": "官网 (酒店当地支付)",
      "wbf": "附机票套餐",
      "tripla": "旅游预订网站（信用卡付款）"
    }
  },
  "reservationAction": {
    "contactTriplaSupport": "联系tripla客服部",
    "applyGOTO": "申请 优惠码",
    "bookingCancel": "取消您的预约",
    "bookingUpdate": "更新顾客详情",
    "corporateSignInRequired": "公司法人若希望更新预约，需登录账户。",
    "differentFacilityWarning": "为了更新您的预约，请透过已预约的设施登录。",
    "everythingCorrectSubtitle": "您可以在此处更改您的資料或取消预约。",
    "everythingCorrectTitle": "管理您的预约",
    "invoiceDownload": "列印此页",
    "madeMistakeHelp": "我们期待下次再为您服务。",
    "madeMistakeTitle": "您的预约已经取消",
    "memberSignInRequired": "需登录会员后，才可更新预约。",
    "pleaseCancelAll": "若想取消所有的客房, 请直接取消预约。",
    "payByCreditCard": "更改為信用卡事前支付",
    "reductionOfNights": "缩短住宿期间",
    "reductionOfRooms": "减少客房数",
    "selectRoomsToCancel": "请选择想取消的客房",
    "signOutRequired": "需退出登录会员后，才可更新预约。",
    "updateExpired": "此预约已超过可变更的期限, 故无法更改。",
    "updateItinerary": "变更预约",
    "differentAccountWarning": "请使用正确的帐户登录以更新预订",
    "differentOrgWarning": "请使用正确的公司法人账户登录以更新预订",
    "coupon": {
      "signOutRequired": "请退出帐户后才申请使用优惠券。",
      "memberSignInRequired": "需登录会员后，才可使用优惠券。",
      "corporateSignInRequired": "需登录公司法人的账户后，才可使用优惠券。",
      "differentFacilityWarning": "如果想使用优惠券，请透过已预约的设施登录。",
      "differentAccountWarning": "请使用正确的帐户登录以使用优惠券。",
      "differentOrgWarning": "请使用正确的公司法人账户登录以使用优惠券。"
    },
    "notEnoughStays": "所选的住宿期间应等于或大于此预约所设定的最少住宿天数（{0}晚）。",
    "viewMyReservations": "查看我的预约",
    "madeMistakeHelp2": "我们期待再次为您服务",
    "madeMistakeTitle2": "已取消预约"
  },
  "reservationCancellation": {
    "roomN": "客房 {0}",
    "cancellationFees": "取消费用",
    "cancellationConfirm2": "确定要取消预约吗？",
    "reason2": "请问取消的原因",
    "reasonTripCancelled2": "旅行计划改变",
    "reasonRoomCheaper2": "其他公司比较划算",
    "reasonNbTrvellers2": "住宿人数改变",
    "reasonDifferentHotel2": "选择了另一家酒店",
    "reasonOther2": "其他（请注明）",
    "cancel": "取消预约",
    "cancellationConfirm": "确定要取消此预约吗？",
    "cancellationFee": "取消费用",
    "cancellationOnDay": "当日取消无法在线上进行。",
    "cancellationPolicy": "取消规定",
    "cancellationTitle": "取消您的预约",
    "contactByChatPhone": "麻烦请使用电话与我们联系。",
    "contactByPhone": "麻烦请使用电话与我们联系。",
    "contactPhone": "电话：{0}",
    "keep": "保留预约",
    "nonRefundable": "无法退款",
    "reason": "请告知您取消的理由？",
    "reasonDifferentHotel": "我选择别家酒店。",
    "reasonNbTrvellers": "行程人数有所变更。",
    "reasonOther": "其他",
    "reasonOtherHelp": "如选择其他，请提供详细理由。",
    "reasonRoomCheaper": "我可在别处以更便宜的价格预约。",
    "reasonTripCancelled": "我的行程已经取消。",
    "roomNbCancelFee": "Room {0} cancellation fee",
    "totalCancelFee": "取消费用总额"
  },
  "reservationConfirmation": {
    "bookingConfirmed": "您的预订已确认",
    "cancelledSuccessfully": "预订成功取消",
    "totalStays": "入住天数",
    "contactProperty": "联络资讯",
    "yourRoomDetails": "房间详细信息",
    "phone": "电话号码",
    "email": "电子邮箱",
    "website": "网页",
    "cancellationFreeUntil": "{0} 之前都可以免费取消",
    "cannotChangePlan": "您无法变更住宿方案。",
    "confirmationEmail": "我们已经向您的邮箱{0}发送邮件。",
    "extra": {
      "answer": "回答",
      "dateTime": "使用日期和时间",
      "extrasPrice": "追加选项",
      "items": "选择数量",
      "notes": "备注",
      "price": "价格",
      "priceIncludeTax": "价格（含税）",
      "question": "问题",
      "roomPrice": "客房总价"
    },
    "extrasSubtotal": "追加选项价格总额",
    "nonRefundableReminder": "此预约不可退款，一经预约，取消时将收取全额费用。",
    "originalReservationPlans": "此预约所选择的住宿方案。",
    "reservationDetail": "预约内容",
    "selectNewItinerary": "请重新选择住宿期间及入住人数并开始搜索。",
    "updateBooking": "变更预约",
    "cannotUpdateTooltip": "因为设施工作人员之前根据您的同意修改了价格及预约内容，目前您无法更新/取消预订。"
  },
  "reservationDetail": {
    "bookingDetails": "确认预订的详情",
    "adult": "{n}位",
    "accommodationTax": "住宿稅",
    "checkIn": "入住日期",
    "checkOut": "退房日期",
    "child": "{n}位",
    "dayUse": "当日休息方案",
    "discount": "折扣",
    "discounts": "折扣",
    "discountedPrice": "兑换点数",
    "guest": "{n}位",
    "guests": "入住人数",
    "hotel": "酒店",
    "maxGuests": "最多可容纳人数",
    "night": "{n}晚",
    "nights": "入住天数",
    "numberOfGuest": "人數",
    "numberOfNights": "入住天数",
    "originalPrice": "总价",
    "pointDiscount": "点数兑换金额",
    "pointInstruction": "您将在退房后的第三天获取点数",
    "pointReward": "此预约可积累{0}分",
    "priceBreakDown": "收费明细",
    "priceDetails": "收费明细",
    "reservationNumber": "预约号码",
    "roomDetails": "客房详情",
    "roomNb": "客房 {0}",
    "roomPlan": "住宿方案",
    "roomRate": "客房价格",
    "roomType": "房型",
    "roomWithChildren": "{nRoom} 间房间容纳 {nAdult} 位成人和 {nChild} 位儿童",
    "roomWithoutChildren": "{nRoom} 间房间容纳 {nAdult} 位成人",
    "searchNecessary": "* 当您需要更改或取消预约时均需要提供此预约编号, 建议您打印此页。",
    "serviceFee": "服务费",
    "selected": "已选择",
    "subTotal": "小计",
    "subTotalWithoutTax": "小计（不含税）",
    "tax": "税",
    "taxIncluded": "含税",
    "total": "支付金额（含税）",
    "totalLengthOfStay": "入住天数",
    "totalPriceWithTax": "含稅总价",
    "depositAmount": "订金金额（{0}%）",
    "remainAmount": "剩余需付金额",
    "withTax": "含税",
    "depositNecessary": "您需要通过我们的线上系统支付{0}%的订金，来完成预约。"
  },
  "reservationPayment": {
    "creditCardPayment": "信用卡提前支付",
    "onSitePayment": "入住时付费(无需事先支付)",
    "paymentMethod": "支付方式",
    "pendingPage": {
      "launchButton": "3D验证",
      "text01": "点击此按钮，转至3D验证密码输入页面。",
      "text02": "若未在15分钟以内完成密码输入，预约处理将会被取消。",
      "text03": "若未输入您的密码，预约将无法完成。空房可能会被其他顾客预约。",
      "text04": "信用卡支付由tripla代为处理，故3D验证的店铺名显示「tripla」。",
      "timeOutError01": "发生超时错误。",
      "timeOutError02": "一般情况下您无需支付任何费用。如信用卡已有支付纪录，相应金额也将退还。详细到帐时间请向信用卡公司确认。",
      "authError01": "支付处理失败。",
      "error01": "预约处理过程中出现一个错误。",
      "error02": "一般情况下您无需支付任何费用。如信用卡已有支付纪录，相应金额也将退还。详细到帐时间请向信用卡公司确认。",
      "error03": "点击「上一页」按钮，在搜索页面再次预约。"
    },
    "planError": "非常抱歉，已选择的方案有相冲突的支付方式，请再次尝试。",
    "selectCreditCards": "请选择要使用的信用卡",
    "sessionErrorMessage": "出现错误，请返回「顾客详细资料」页面并重试。",
    "sessionErrorButtonText": "返回「顾客详细资料」"
  },
  "reservationReceipt": {
    "customerName": "收据抬头",
    "deferredWithoutDownload": "因为公司代付，所以不提供发票。",
    "download": "下载收据",
    "downloadOnCheckout": "您可在退房日当日或之后下载收据。",
    "onSite": "若选择在酒店当地支付，前台会为您发行收据。"
  },
  "searchBar": {
    "addRoom": "加订一间客房",
    "adult": "{n}位大人",
    "adults": "大人",
    "age": "年龄",
    "agree": "同意",
    "bed": "加床铺",
    "checkIn": "入住日期",
    "checkInAndOut": "办理入住 - 退房日期",
    "checkOut": "退房日期",
    "child": "{n}位儿童",
    "childN": "儿童 {n}",
    "children": "儿童",
    "childrenAgeAtCheckout": "退房时儿童的年龄",
    "childrenAgeInfant": "年龄 {from}-{to}",
    "childrenAgePrimary": {
      "primary": "年龄 {from}-{to}",
      "primaryBottom": "年龄 {from}-{to}",
      "primaryTop": "年龄 {from}-{to}"
    },
    "childrenAges": "同行儿童年龄",
    "childrenAgeSharedBed": "年龄 {from}-{to}",
    "childrenMealBedding": {
      "meal_no": "不含用餐和床铺",
      "meal_no_bedding": "含床铺",
      "meal_same_as_adult_bedding": "含用餐和床铺",
      "meal_yes": "含用餐",
      "meal_yes_bedding": "含用餐和床铺"
    },
    "cookieConsent": "此网站使用cookies来提高用户体验。若继续使用此网站，则表示您已经同意我们使用cookies。若希望了解更多详情，请查看{0}。",
    "privacyPolicy": "隐私条例",
    "perRoomGuests": "一间房入住的人数",
    "dates": "住宿期间",
    "dayUse": "搜索当日休息方案",
    "defaultArea": "全部地区",
    "edit": "更改",
    "filter": "选择条件",
    "guests": "入住人数",
    "hotel": "酒店",
    "maxChildrenWarning": "每位大人最多可携带{n}位儿童。",
    "pleaseSelectHotel": "请选择酒店名",
    "room": "{n}间房间",
    "rooms": "房间",
    "search": "开始搜寻",
    "showOnlyAvailable": "仅显示可预约的房间/方案",
    "sort": "自行排序",
    "sortOption": {
      "price_high_to_low": "按据价格排序 (从高至低)",
      "price_low_to_high": "按价格排序 (从低至低)",
      "recommended": "按推荐排序",
      "asc": "按价格排序 (从低至低)",
      "desc": "按据价格排序 (从高至低)"
    },
    "to": "至",
    "undated": "日期未定",
    "nights": "入住天数",
    "yearOld": "{n} 岁",
    "enableWBFSearch": "有效搜寻住宿+机票方案",
    "stayFlightTab": "住宿+机票",
    "stayTab": "住宿",
    "processInJapTxt": "预约流程将以日文进行。",
    "processMaxTxt": "预约流程将以日文进行，最大预约房间数为5。",
    "undatedWarning": "若每间客房的人数不一样，则无法在不输入入住日期的情况下，搜索空房。",
    "undatedWarning2": "因为您已选择搜索附机票的房间，这个无法启动。",
    "flightAndRoomsWarning": "因为您已选择搜索日期未定的房间，这个无法启动。"
  },
  "searchReservation": {
    "enterDetail": "请输入您的预约号及电子邮箱",
    "enterReservationNumber": "请输入预约号码",
    "manageBooking": "住宿预约确认・取消",
    "searchFailed": "我们需要以下的信息进行下一步。很抱歉，电子邮箱或预约号码不正确。请再试一次。",
    "confirmReservation": "预约确认",
    "confirmReservationDesc": "因为此预约是在{ota}上支付，将在{time}秒后重新引导至{ota}。"
  },
  "searchResultByPlan": {
    "hide": "隐藏",
    "planContent": "方案内容",
    "plans": "方案",
    "rooms": "客房",
    "showPlan": "显示方案详情",
    "moreDetail": "显示更多"
  },
  "snsLogin": {
    "loginWith": "以{0}登录",
    "enterPassword": "请输入您的密码以继续",
    "existingPrompt": "你好像有一个现有帐户。请问要将您的帐户与{sns}连接吗？您之后可以使用{sns}或电子邮件/密码来登录。",
    "signUpDescription": "非常感谢您连接您的 {sns} 帐号, 请提供下述的资讯以完成创建帐号。"
  },
  "voucher": {
    "cancel": "取消变更",
    "confirm": "确认",
    "number": "预约号码",
    "purchseComplete": "已购买",
    "selectStay": "请选择入住日期",
    "stillVoucher": "目前此住宿折扣券仍未使用, 并没有任何预约客房的纪录及其入住/退房日。",
    "thankPurchase": "感谢您购买住宿折扣券。",
    "update": "确定变更",
    "updateItinerary": "变更预约",
    "updateItineraryDesc": "请更改住宿日期。点击「确定变更」的按钮，确定变更。",
    "usingVoucher": "使用此住宿折扣券预约客房",
    "valid": "有效的住宿折扣券",
    "voucherDesc": "您可以使用此住宿折扣券预约客房, 请在此选择入住日及退房日。",
    "voucherDetails": "住宿折扣券详情",
    "voucherNumber": "住宿折扣券代码"
  },
  "triplaPay": {
    "notFoundMsg": "未找到付款记录。此交易将无法完成",
    "isPaid": "本次支付成功完成",
    "isAlreadyPaid": "已付款",
    "isFailed": "本次支付未能成功",
    "isCompleted": "本次支付成功完成",
    "isRefunded": "您的支付已退还",
    "refundedAt": "退款日期",
    "paidAt": "支付日期",
    "isCancelled": "本次支付已取消",
    "total": "支付金额",
    "pleaseReload": "此任务未能在一小时内完成，请重新加载。",
    "reload": "重新加载",
    "subject": "支付主体",
    "amountIsChanged": "支付金额已变更，请点击「更新」按钮，重新加载。",
    "terms": "本交易系统由tripla (公司) 提供，请客人理解个人信息将与tripla (公司)共享的条款后，并同意tripla {0}、{1} 和{2}，",
    "paypalTerms": "信用卡交易会使用PayPal，使用详情会记载在您的信用卡账单上。",
    "temsOfUse": "使用规则",
    "privacyPolicy": "隐私条例",
    "gdprPolicy": "GDPR政策",
    "pleaseScan": "请扫描二维码"
  },
  "triplaPayIssues": {
    "INVALID_PARAMETER_SYNTAX": "请检查你输入的信息",
    "INVALID_EXPIRATION_YEAR": "请确认有效日期是否正确。",
    "Invalid_card_number": "请确认信用卡卡号。",
    "Others": "请检查你输入的信息"
  },
  "lottery": {
    "registerForLottery": "申请抽奖",
    "selectedDatesError": "所选的住宿期间应在此抽奖活动所设定的最少及最大的住宿天数范围內（{0} ~ {1} 晚）。"
  },
  "recommenderWidget": {
    "previewText": "这是预览模式，仅有效期5分钟。请在5分钟后再次点击“预览”。别忘了为实际的上线版本保存您的设置。"
  }
}